'use strict';

import "core-js/stable";
import "regenerator-runtime/runtime";

const TRANSITION_END = 'webkitTransitionEnd transitionend';
// const BREAK_POINT = 960;
import BREAK_POINT from './global';


require("picturefill");
import 'objectFitPolyfill';
// import "jquery.scrollbar";
import Swiper from 'swiper/bundle'
// import 'slick-carousel';
// import anime from 'animejs'
// import Rellax from 'rellax';
import ScrollHelper from './scrollhelper.js';
import ResizeHelper from './resize.js';
import smoothscroll from 'smoothscroll-polyfill';


import SmoothScroll from './smoothScroll.js';
// import Intersection from './Intersection.js'
// import MV from './MV.js'
// import Device from './device.js';
import 'url-polyfill';
import ScrollTrigger from '@terwanerik/scrolltrigger'

const $body = $('body');
const $window = $(window);


// const mv = new MV()
// if(!document.body.classList.contains('dev')) {
//   mv.init()
// }

$(() => {

  const $body = $('body');
  const $window = $(window);

  $body.addClass('ready');


  // if(!document.body.classList.contains('dev')) {
  //   mv.start()
  // }


  // スクロールエフェクト --------------------------------------------------
  const trigger = new ScrollTrigger({
    trigger: {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.25
          }
        },
        viewport: {
          x: 0,
          y: (trigger, frame, direction) => {
            return 0.3
          }
        }
      },
    },
  })
  trigger.add('[data-trigger]')

  const works = new Swiper('.works-slider', {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 0,
    speed: 600,
    noSwiping: false,
    allowTouchMove: true,
    loop: true,
    autoplay: false,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    // },
    navigation: {
      nextEl: '.works-slider__arrow--next',
      prevEl: '.works-slider__arrow--prev',
    },
    // pagination: {
    //   el: '.works-slider__readout',
    //   type: 'fraction',
    //   renderFraction: function (currentClass, totalClass) {
    //     return '<span class="' + currentClass + '"></span>/<span class="' + totalClass + '"></span>';
    //   }
    // },
    centeredSlides: true,
    breakpoints: {
      // when window width is >= 320px
      // 320: {
      //   slidesPerView: 2,
      //   spaceBetween: 20
      // },
      // // when window width is >= 480px
      // 480: {
      //   slidesPerView: 3,
      //   spaceBetween: 30
      // },
      // when window width is >= 640px
      960: {
        centeredSlides: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        // slidesPerView: 4,
        // spaceBetween: 40
      }
    }
  })
  $('.js_modal').on('click', e => {
    works.autoplay.stop()
    const $img = $('.js_modal-image')
    const caption = e.currentTarget.getAttribute('data-caption')
    const src = e.currentTarget.getAttribute('data-target')
    const img = new Image()
    img.src = src
    $img.attr('src', src)
    
    img.addEventListener('load', e => {
      $('.js_modal-caption').text(caption)
      $('body').addClass('modal-active')
    })
  })

  $('.js_modal-close').on('click', e => {
    works.autoplay.start()
    $('body').removeClass('modal-active')
  })


  // menu
  $('.js_open-menu').on('click', () => {
    $body.addClass('menu-active');
  });
  $('.js_toggle-menu').on('click', () => {
    $body.toggleClass('menu-active');
  });
  $('.js_close-menu').on('click', () => {
    $body.removeClass('menu-active');
  });

  // setInterval(e => {
  //   $('.anim_section-title').toggleClass('visible')
  // }, 3500)


  // bodyclass(scrolled)
  let windowHeight = window.innerHeight;
  const resizeHelper = new ResizeHelper(() => {
    windowHeight = window.innerHeight;
  })
  const stickyHeader = new ScrollHelper(function(){
    $body.toggleClass('scrolled', $(window).scrollTop() >= windowHeight);
  });

  // scroll label
  // const scrollSign = new ScrollHelper(() => {
  //   if($(window).scrollTop() >= 50){
  //     scrollSign.destroy();
  //     $('#scroll').css('opacity', 0)
  //   }
  // });

  // -----------------------------------------------------------------------------
  // INDEX

  

  // scroll
  const smoothScroll = new SmoothScroll({
    breakpoint: BREAK_POINT,
    offset: {
      pc: 0,
      sp: 0
    }
  });
  smoothScroll.begin = () => {
    if($window.width() < BREAK_POINT){
      $body.removeClass('menu-active');
    }
  }

  objectFitPolyfill();
  // smoothscroll.polyfill();
  // window.__forceSmoothScrollPolyfill__ = true;


});
